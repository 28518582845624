<template>
  <div>
    <van-nav-bar
      title="发起审批"
      left-text="返回"
      left-arrow
      @click-left="() => $router.back()"
    />
    <div style="margin: 10px 10px 0 0; border: 0px solid red">
      <form-create
        :rule="rule"
        :option="option"
        @submit="onSubmit"
      ></form-create>
    </div>
  </div>
</template>

<script>
import api from '@/api/process'

export default {
  name: 'process',

  data() {
    return {
      processTemplate: {},
      rule: [],
      option: {},
      departmentList: [], // 部门数据
      personnelList: [], // 人员数据
    }
  },

  created() {
    let processTemplateId = this.$route.params.processTemplateId
    this.fetchData(processTemplateId)
    // 获取部门数据
    this.fetchDepartmentData()
  },

  methods: {
    // fetchData(processTemplateId) {
    //   api.getProcessTemplate(processTemplateId).then((response) => {
    //     console.log(response.data)
    //     this.processTemplate = response.data

    //     this.rule = JSON.parse(this.processTemplate.formProps)
    //     this.option = JSON.parse(this.processTemplate.formOptions)

    //     // 如果是特定类型的模板，动态添加联级选择框
    //     if (this.processTemplate.templateType === 1) {
    //       this.addDepartmentAndPersonnelFields()
    //     }
    //     // 如果有多种模板
    //   })
    // },

    async fetchData(processTemplateId) {
      const response = await api.getProcessTemplate(processTemplateId)
      console.log(response.data)
      this.processTemplate = response.data
      this.rule = JSON.parse(this.processTemplate.formProps)
      this.option = JSON.parse(this.processTemplate.formOptions)

      if (this.processTemplate.templateType === 1) {
        await this.addDepartmentAndPersonnelFields()
      }
    },

    // 动态添加部门和人员选择字段
    async addDepartmentAndPersonnelFields() {
      await this.fetchDepartmentData()
      console.log(
        '部门列表（非响应式代理）:',
        this.departmentList.map((item) => ({
          label: item.label,
          value: item.value,
        }))
      )

      const staticOptions = [
        { label: '部门A', value: '1' },
        { label: '部门B', value: '2' },
      ]

      const departmentField = {
        type: 'select',
        field: 'department',
        title: '选择部门',
        props: {
          placeholder: '请选择部门',
          multiple: true,
          options: [
            { label: '部门A', value: '1' },
            { label: '部门B', value: '2' },
          ], // 直接赋值
        },
        validate: [{ required: true, message: '请选择部门' }],
        on: {
          change: this.onDepartmentChange,
        },
      }

      const personnelField = {
        type: 'select',
        field: 'personnel',
        title: '选择人员',
        props: {
          placeholder: '请选择人员',
          multiple: true,
          options: [], // 初始为空
        },
        validate: [{ required: true, message: '请选择人员' }],
      }

      // 替换整个 rule 数组，触发重新渲染
      this.rule = [...this.rule, departmentField, personnelField]

      // 确保视图更新
      this.$forceUpdate()
    },

    // 获取部门数据
    async fetchDepartmentData() {
      try {
        const response = await api.getDepartmentList()
        this.departmentList = response.data.map((item) => ({
          label: item.name,
          value: item.id,
        }))
        console.log('部门列表:', this.departmentList) // 打印检查数据格式
      } catch (error) {
        console.error('部门数据加载失败:', error)
      }
    },

    // 根据选中的部门获取对应的人员数据
    onDepartmentChange(selectedDepartments) {
      if (selectedDepartments.length > 0) {
        api.selectedDepartments(selectedDepartments).then((response) => {
          this.personnelList = response.data.map((item) => ({
            label: item.name,
            value: item.id,
          }))

          // 更新人员选择框的 options
          const personnelField = this.rule.find(
            (item) => item.field === 'personnel'
          )
          if (personnelField) {
            personnelField.props.options = this.personnelList
          }
        })
      }
    },

    onSubmit(formData) {
      console.log(formData)
      let formShowData = {}
      this.rule.forEach((item) => {
        for (let key in formData) {
          if (key === item.field) {
            console.log(item.title, formData[key])
            formShowData[item.title] = formData[key]

            // 保存审批人名字（针对人员字段）
            if (key === 'personnel') {
              selectedPersonnel.push(
                ...formData[key].map(
                  (id) => this.personnelList.find((p) => p.value === id)?.label
                )
              )
            }
          }
        }
      })
      let DATA = {
        formData: formData,
        formShowData: formShowData,
      }
      console.log(DATA)

      // 拼接审批人名字
      if (selectedPersonnel.length > 0) {
        DATA.formShowData[
          'approvalNameList'
        ] = `审批人: ${selectedPersonnel.join(', ')}`
      }

      let processFormVo = {
        processTemplateId: this.processTemplate.id,
        processTypeId: this.processTemplate.processTypeId,
        formValues: JSON.stringify(DATA),
      }

      // 提取名字列表
      let approvalNameList = []
      if (DATA.formShowData['approvalNameList']) {
        // 假设 "审批人" 是选中的名字对应的字段
        approvalNameList = DATA.formShowData['approvalNameList']
          .split('，') // 根据分隔符分割
          .map((name) => name.trim()) // 去掉多余空格
      }

      // 如果有选中的名字，添加到 processFormVo
      if (approvalNameList.length > 0) {
        processFormVo.approvalNameList = approvalNameList
      }

      console.log(processFormVo)
      api.startUp(processFormVo).then((response) => {
        //调整到已发起列表
        this.$router.push({ path: '/list/2' })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.el-form {
  .el-form-item {
    /deep/ .el-form-item__label {
      font-size: 18px;
      font-weight: 800;
      color: blue;
    }
  }
}
</style>
